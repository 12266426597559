import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import {AioLanguageSwitcher, AioPage} from '@autopay.io/style';
import {PriceCalculatorIndex} from './scenes/priceCalculator';
import {Translation} from '@autopay.io/translation';
import {AioHeaderOptions} from '@autopay.io/style/lib/components/Layout/AioPageTypes';

export const routes = {
    ROOT: '/:facility',
};

export const headerWithElements: AioHeaderOptions = {
    right: [
        {
            element:
                (
                    <AioLanguageSwitcher
                        supportedLanguages={Translation.supportedLanguages}
                        setSelectedLanguage={Translation.setSelectedLanguage}
                        getSelectedLanguageName={Translation.getSelectedLanguageName}
                    />
                ),
        },
    ],
    style: 'orange',
};

const Router = () => {

    return (
        <AioPage type="page-with-header" header={headerWithElements}>
            <Switch>
                <Route path={routes.ROOT}>
                    <PriceCalculatorIndex />
                </Route>
            </Switch>
        </AioPage>
    );
};

export default Router;
